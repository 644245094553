jQuery.noConflict();
jQuery(document).ready(function($) {


    // Preloader
    $(window).on('load', function() { // makes sure the whole site is loaded 
        $('.preloader-gif').fadeOut(); // will first fade out the loading animation 
        $('.preloader-slider').delay(350000).fadeOut('slow'); // will fade out the white DIV that covers the website. 
        $('.slider-principal').delay(350000).css({'overflow':'visible'});
    });

    
    // on load
    $(window).on('load', function() {

        // Carousel 

        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            margin: 0,
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
            
        });

        
        $('.novetats').owlCarousel({
            //pagination: false,
            nav: false,
            loop: false,
            dots: true,
            margin: 30,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1200: {
                    items: 3
                },
            }
        });

        $('.galeria').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });

        $('.relacionados').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            margin: 30,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }

        });


        

        $(".owl-prev").html('<i class="icon-f-left"></i>');
        $(".owl-next").html('<i class="icon-f-right"></i>');


    });

    // Datepicker
    $('.datepicker').datepicker({
        closeText: 'Tancar',
        prevText: '<i class="ei-arrow_carrot-left_alt"></i>',
        nextText: '<i class="ei-arrow_carrot-right_alt"></i>',
        currentText: 'Avui',
        monthNames: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['diumenge', 'dilluns', 'dimarts', 'dimecres', 'dijous', 'divendres', 'dissabte'],
        dayNamesShort: ['dg.', 'dl.', 'dt.', 'dc.', 'dj.', 'dv.', 'ds.'],
        dayNamesMin: ['dg', 'dl', 'dt', 'dc', 'dj', 'dv', 'ds'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    });



    // Toast
    $('.toast').toast({

    });

    // Contact forms
    $('.wpcf7-form .wpcf7-text').addClass('form-control');
    $('.wpcf7-textarea').addClass('form-control');
    $('.wpcf7-date').addClass('form-control');
    $('.wpcf7-submit').addClass('btn btn-primary');

    // Responsive iframes
    $("iframe").wrap('<div class="embed-responsive embed-responsive-16by9"/>');
    $("iframe").addClass('embed-responsive-item');


    // Popup
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });


});